<template>
    <div class="modal create-material">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title"><strong>Legg til nytt materiale</strong></p>
            </header>
                <section class="modal-card-body">
                        <button @click="handleFormChange('guide', 'video')" class="button video mr-2 btn-active">Video</button>
                        <button @click="handleFormChange('video', 'guide')" class="button guide">Veiledning</button>
                        <form v-show="showVideoForm">
                            <input v-model="title" required type="text" class="input my-4" placeholder="Tittel">
                            <input v-model="description" required type="text" class="input" placeholder="Beskrivelse">
                            <div class="select mt-4 mb-2 is-dark">
                                <select v-model="playlist" required>
                                    <option disabled value="">Velg spilleliste</option>
                                    <option>Skade</option>
                                    <option>Forarbeid</option>
                                    <option>Etterarbeid</option>
                                    <option>Lakkering</option>
                                    <option>Vedlikehold</option>
                                </select>
                            </div>
                            <div class="select my-2 is-dark">
                                <select v-model="url" required class="video-select">
                                    <option disabled value="">Velg video</option>
                                </select>
                            </div>
                            <div class="select my-2 is-dark">
                                <select v-model="thumbnail" required class="thumbnail-select">
                                    <option disabled value="">Velg thumbnail</option>
                                </select>
                            </div>
                            <div class="select my-2 is-dark">
                                <select v-model="attachment" required class="video-attachments-select">
                                    <option disabled value="">Velg vedlegg</option>
                                </select>
                                <button @click.prevent="addAttachment()" class="button is-dark my-2"><font-awesome-icon style="color: white;" icon="fa-solid fa-plus" /></button>
                                <div class="attacment-name-container">
                                    <label>Valgte vedlegg</label>
                                    <div class="columns" v-for="attachment, idx in attachments" :key="idx">
                                        <div class="column attachment">
                                            {{  attachment.name }}
                                        </div>
                                        <div class="column is-1">
                                            <font-awesome-icon  @click="removeFromAttachmentArray(idx)" style="color: #414141;" icon="fa-solid fa-trash" class="attachmenet-delete"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form v-show="showGuideForm">
                            <input v-model="title" required type="text" class="input my-4" placeholder="Tittel">
                            <input v-model="description" required type="text" class="input" placeholder="Beskrivelse">
                            <div class="select mt-4 mb-2 is-dark">
                                <select v-model="playlist" required name="playlist">
                                    <option disabled value="">Velg spilleliste</option>
                                    <option>Skade</option>
                                    <option>Forarbeid</option>
                                    <option>Etterarbeid</option>
                                    <option>Lakkering</option>
                                    <option>Vedlikehold</option>
                                </select>
                            </div>
                            <div class="select my-2 is-dark">
                                <select v-model="url" required class="guide-select">
                                    <option disabled value="">Velg veiledning</option>
                                </select>
                            </div>
                            <div class="select my-2 is-dark">
                                <select v-model="thumbnail" required class="thumbnail-guide-select">
                                    <option disabled value="">Velg thumbnail</option>
                                </select>
                            </div>
                            <div class="m-2">
                                <h3 class="title is-6 mb-2">Kun tilgjengelig for lærlinger</h3>
                                <o-field>
                                    <o-switch variant="success" v-model="apprenticeOnly"></o-switch>
                                </o-field>
                            </div>
                        </form>
                </section>
                <footer class="modal-card-foot">
                    <button @click="handleSubmit(showVideoForm)" class="button is-primary">Opprett</button>
                    <button @click="handleCancel()" class="button">Avbryt</button>
                </footer>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                staticS3Path: 'https://cc-vidoeportal.s3.eu-north-1.amazonaws.com/',
                thumbnail: '',
                url: '',
                playlist: '',
                title: '',
                description: '',
                apprenticeOnly: false,
                showVideoForm: true,
                showGuideForm: false,
                attachment: '',
                attachments: []
            }
        },
        methods: {
            handleFormChange(classNameToActivate, classNameToDisable) {
                document.querySelector(`.${classNameToActivate}`).classList.toggle('btn-active')
                document.querySelector(`.${classNameToDisable}`).classList.toggle('btn-active')

                this.showVideoForm = !this.showVideoForm
                this.showGuideForm = !this.showGuideForm
            },
            handleCancel() {
                this.$parent.showCreateMaterial = false
                this.thumbnail = '',
                this.url = '',
                this.playlist = '',
                this.title = '',
                this.description = '',
                this.showVideoForm = true,
                this.showGuideForm = false,
                this.attachment = '',
                this.attachments = []
            },
            handleSubmit(content) {
                if(content === true) {
                    this.postVideo()
                } else {
                    this.postGuide()
                }
            },
            async postVideo() {
                try {
                const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}/videos/upload`, {
                    title: this.title,
                    description: this.description,
                    playlist: this.playlist,
                    url: this.url,
                    thumbnail: this.thumbnail,
                    attachments: this.attachments
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.$parent.getToken}`
                    }
                })
                if(response.data) {
                    this.$parent.showCreateMaterial = false
                    this.$parent.notification.message = response.data.msg
                    this.$parent.notification.className = response.data.className
                    this.title = '',
                    this.description = '',
                    this.playlist = ''
                    this.url = '',
                    this.thumbnail = '',
                    this.attachments = []
                }
                } catch(err) {
                    console.log(err)
                    this.$parent.showCreateMaterial = false
                    this.$parent.notification.message = err.response.data.msg
                    this.$parent.notification.className = err.response.data.className
                }
            },
            async postGuide() {
                try {
                const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}/api/v1/guides/upload`, {
                    title: this.title,
                    description: this.description,
                    playlist: this.playlist,
                    url: this.url,
                    thumbnail: this.thumbnail,
                    apprenticeOnly: this.apprenticeOnly
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.$parent.getToken}`
                    }
                })
                if(response.data) {
                    this.$parent.showCreateMaterial = false
                    this.$parent.notification.message = response.data.msg
                    this.$parent.notification.className = response.data.className
                    this.title = '',
                    this.description = '',
                    this.playlist = ''
                    this.url = '',
                    this.thumbnail = ''
                }
                } catch(err) {
                    console.log(err)
                    this.$parent.showCreateMaterial = false
                    this.$parent.notification.message = err.response.data.msg
                    this.$parent.notification.className = err.response.data.className
                }
            },
            getAvailableFiles(fileType, parentElement) {
            fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/bucket/get', {
                headers: {
                    'Authorization': 'Bearer ' + this.$parent.getToken
                }
            })
            .then(response => response.json())
            .then(data => this.addFileKeysToSelect(data, fileType, parentElement))
            },
            addFileKeysToSelect(fileArray, fileType, parentElement) {
                const parent = document.querySelector(parentElement)
                fileArray.forEach((file) => {
                    if(this.getFileType(file.Key) === fileType) {
                        const optionElement = document.createElement('option')
                        optionElement.value = this.staticS3Path + file.Key
                        optionElement.label = file.Key
                        parent.appendChild(optionElement)
                    }
                })  
            },
            getFileType(fileName) {
            const regex = /(?:\.([^.]+))?$/
            const fileType = regex.exec(fileName)[1]
            return fileType
            },
            addAttachment(e) {
                this.attachments.push({
                    location: this.attachment,
                    name: this.attachment.split('/')[3]
                })
            },
            removeFromAttachmentArray(index) {
                if(this.attachments.length === 1) {
                    this.attachments = []
                } else {

                }
               this.attachments = this.attachments.slice(0, index).concat(this.attachments.slice(index + 1))
            }
        },
        mounted() {
            this.getAvailableFiles('mp4', '.video-select')
            this.getAvailableFiles('jpg', '.thumbnail-select')
            this.getAvailableFiles('pdf', '.guide-select')
            this.getAvailableFiles('jpg', '.thumbnail-guide-select')
            this.getAvailableFiles('pdf', '.video-attachments-select')
            // this.addFileKeysToSelect(this.files, 'mp4', '.video-select')
            // this.addFileKeysToSelect(this.files, 'jpg', '.thumbnail-select')
        }
    }
</script>

<style scoped>
    .modal-card {
        height: 650px;
    }
    .btn-active {
        border: 2px solid black;
    }

    .btn-active:focus {
        border-color: black;
        box-shadow: none;
    }

    .attachment {
        font-size: .65rem;
    }
    .attachmenet-delete:hover {
        cursor: pointer
    }
</style>