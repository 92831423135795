<template>
    <div class="wrapper">
        <div><h1 style="font-size: 150px;" class="title status">404</h1><br></div>
        <div><h4 style="font-size: 50px;" class="title error">Finnes ikke..🤔</h4><br></div>
        <p style="font-size: 25px;" class="paragraph">Siden du leter etter finnes ikke på serveren. Trykk <a href="/">her</a> for å gå tilbake</p>
    </div>

</template>

<script>
</script>
<style scoped>
.emoji {
    font-size: 150px;
}

html, body {
    height: 100vh;
}
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
</style>