<template>
<nav>
    <router-link to="/dashboard">Dashboard</router-link>
    <router-link to="/published">Innhold</router-link>
    <router-link to="/media-library">Mediabiblotek</router-link>
    <router-link to="/content">Kursportal</router-link>
</nav>
</template>

<script>

</script>

<style scoped>
nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color:#2f2f2f;
    color: white; 
    height: 50px;
    align-items: center;
}

nav a {
    color: white!important;
    font-size: 18px;
}

nav a.router-link-exact-active {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
}

nav a:hover {
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
}


</style>