<template>
    <nav>
            <router-link to="/content">Kursportal</router-link>
            <router-link to="/guides">Veiledninger</router-link>
            <router-link to="/archive">Arkiv</router-link>
            <router-link v-if="this.getUser.position === 'Lærling' || this.getRole == 'admin'" to="/apprentice">Lærlingkurs</router-link>
            <router-link v-if="this.getRole == 'admin'" to="/dashboard">Dashboard</router-link>
            <font-awesome-icon style="color: white;" icon="fa-solid fa-inbox" size="xl"/>
    </nav>
    </template>
    
    <script>
import { mapGetters } from 'vuex';

        
        export default {
            data() {
                return {
                    user: this.getUser,
                }
            },
            methods: {
                logOut() {
                    this.$store.isLoggedIn = null,
                    this.$store.token = null
                    this.$store.role = null
                    this.$router.push('/')
                }
            },
            computed: {
                ...mapGetters([
                    'getToken',
                    'getRole',
                    'getUser'
                ])
            }
        }
    </script>
    
    <style scoped>
    nav {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color:#2f2f2f;
        color: white; 
        height: 75px;
        align-items: center;
        position: relative;
        z-index: 100;
    }
    
    nav a {
        color: white!important;
        font-size: 18px;
    }
    
    nav a.router-link-exact-active {
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 5px;
    }
    
    nav a:hover {
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-underline-offset: 5px;
    }
    
    
    </style>