<template>
    <div class="password-change-wrapper">
        <div class="password-change-card card p-6">
            <div class="card-content">
                <h3 class="title is-3">Glemt passord</h3>
                <form @submit.prevent="handleSubmit()">
                    <div class="field">
                        <label class="label">Passord</label>
                        <div class="control has-icons-left has-icons-right">
                            <input required v-model="password" class="input" type="password" placeholder="Passord">
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="fa-solid fa-lock" />
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Gjenta passord</label>
                        <div class="control has-icons-left has-icons-right">
                            <input required v-model="password_repeat" class="input" type="password" placeholder="Gjenta passord">
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="fa-solid fa-lock" />
                            </span>
                        </div>
                    </div>
                    <button class="button password-change-btn is-dark mb-2">Bytt passord</button>
                    <div class="links-login my-2">
                    <a href="/">Logg på</a>
                    </div>
                    <div v-if="message" :class="className" class="notification">
                        {{ message }}
                    </div>
                </form>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    import { validatePassword } from '../functions'
    export default {
        data() {
            return {
            message: '',
            className: '',
            password: '',
            password_repeat: ''
            }
        },
        methods: {
            async handleSubmit() {
                try {
                    if(validatePassword(this.password) == 1) {
                        if(this.password === this.password_repeat) {
                            const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/users/reset/' + this.$route.params.string, {
                            password: this.password,
                            })
                            if(response.status === 200) {
                                this.message = 'Passord er nå tilbakestilt.'
                                this.className = 'is-success'
                                this.password = '',
                                this.password_repeat = ''
                            } else {
                                this.message = 'Et ukjent problem har oppstått. Ta kontakt med oss'
                                this.className = 'is-danger'
                            }   
                        } else {
                            this.message = 'Passordene samsvarer ikke'
                            this.className = 'is-warning'
                        }
                    } else {
                        this.message = 'Passordet ditt oppfyller ikke kravene. Vi krever et passord mellom 6 og 20 karakterer. Minst ett tall, en stor- og en liten bokstav.'
                        this.className = 'is-warning'
                    }
                } catch(err) {
                    console.log(err)
                    this.message = err.response.data.msg
                    this.className = 'is-danger'
                }
            }
        }
    }
    </script>
    <style scoped>
    .password-change-wrapper {
        width: 100vw;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('@/assets/images/background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .password-change-card {
        border-right: 10px solid #414141;
        width: 600px;
    }

    .password-change-btn {
        width: 100%;
    }

    a {
        color: #414141;
        font-size: 12px;
    }
    a:hover {
        text-decoration: underline;
    }
    </style>