<template>
    <div class="modal file-upload">
  <div class="modal-background"></div>
  <div v-if="notification.message" :class="[notification.className]" class="notification is-primary">
    <button @click="wipeNotification()" class="delete"></button>
        {{ notification.message }}
    </div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title"><strong>Last opp til mediabiblotek</strong></p>
    </header>
    <section class="modal-card-body">
            <p class="mb-2">Støttede filformater: <strong>.pdf, .mp4, .jpg</strong></p>
            <form @submit.prevent="handleSubmit" id="upload-form" class="upload-form">
            <div v-show="isLoading" class="upload-started" style="margin-bottom: 20px;">
                <p>Laster opp element. Fremgang: {{ progress }}%</p>
                <div class="progress-bar-container">
                    <div class="progress-bar">
                    </div>
                </div>
            </div>
            <!-- <input @change="handleChange" class="input file" type="file"/> -->
            <div class="file has-name is-fullwidth">
                  <label class="file-label">
                    <input @change="handleChange" id="file" class="file-input input file" type="file">
                    <span class="file-cta">
                      <span class="file-icon">
                        <font-awesome-icon icon="fa-solid fa-upload" />
                      </span>
                      <span class="file-label">
                        Fil
                      </span>
                    </span>
                    <span class="file-name">
                        {{ file.name }}
                    </span>
                  </label>
                </div>
            </form>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-primary" form="upload-form" type="submit">Last opp</button>
      <button @click="handleCancel()" class="button">Avbryt</button>
    </footer>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { getFileType } from '../functions'

export default {
    data() {
        return {
            file: {
                name: 'Filnavn'
            },
            progress: '',
            isLoading: '',
            showMediaModal: false,
            notification: {
              message: '',
              className: ''
            }
        }
    },
    methods: {
        handleSubmit() {
            if(!this.checkFileType() && this.fileName != 'Filnavn') {
              this.notification.message = 'Du prøver å laste opp en filtype som ikke er støttet, eller så har du ikke valgt en fil👇'
              this.notification.className = 'is-danger'
            }
            fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}/api/v1/upload/getSignedUrl?key=${this.file.name}&fileType=${this.file.type}`, {
              headers: {
                'Authorization': 'Bearer ' + this.$parent.getToken
              }
            })
            .then(response => response.json())
            .then(data => axios.put(data.url, this.file, { onUploadProgress: (e) => {
                this.isLoading = true
                this.progress = Math.ceil(e.loaded / e.total * 100)
                // console.log(document.querySelector('.progress-bar').style.width = `${this.progress}%`)
                document.querySelector('.progress-bar').style.width = `${this.progress}%`
            }}))
            .then((result) => {
                if(result.status == 200) {
                    this.isLoading = false     
                    this.notification.message = `Filen ${this.file.name} ble lastet opp 👍`
                    this.notification.className = 'is-success'
                }
            })
            .catch(function(err) {
              console.log(err)
              this.notification.message = 'Det har skjedd en feil'
              this.notification.className = 'is-danger'
            })
        },
        handleChange() {
            this.file = document.querySelector('.file-input').files[0]
        },
        handleCancel() {
            document.querySelector('.file-upload').classList.toggle('is-active')
        },
        wipeNotification() {
          this.notification.message = '',
          this.notification.className = ''
        },
        checkFileType() {
          const acceptedFileTypes = ['pdf', 'jpg', 'mp4']
          return acceptedFileTypes.includes(getFileType(this.file.name))
        }
    }
}

</script>

<style scoped>
.progress-bar-container {
    border: 2px black solid;
    border-radius: 5px;
    display: flex;
    background-color: #fff;
    width: 100%;
    height: 29px;
    }

    .progress-bar {
    height: 25px;
    background-color: #00a516;
    width: 65%;
    }

    .button {
        margin-top: 10px;
    }

</style>