<template>
    <div :class="{'is-active': this.$parent.showVideoModal}" class="modal video-modal">
          <div @click="handleCancel()" class="modal-background"></div>
          <div v-if="mileStoneMessage" class="notification is-primary">
          <button class="delete"></button>
          {{ mileStoneMessage }}
        </div>
          <div class="modal-card user-info-card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ video.title }}</p>
              <button @click="handleCancel()" class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
              <div class="m-2 columns is-dark video-body is-flex-direction-column">
                <video
                ref="videoPlayer"
                :poster="video.tumbnail"
                :src="video.url"
                :width="575"
                @timeupdate="onPlayerTimeupdate"
                controlsList="nodownload" 
                :controls="true">
                </video>
                <h3 class="title is-4 my-2">Beskrivelse</h3>
                {{ video.description }}
                  <h3 class="title is-5 mt-2 mb-1">Vedlegg 📄</h3>
                  <a v-for="attachment in video.attachments" class="my-2" target="_blank" :href="attachment.location">{{ attachment.name }}</a>
                <div class="star-rating my-2">
                  <p class="mb-2"><strong>Likte du innholdet? 👇 </strong></p>
                  <star-rating @update:rating="setRating" class="my-2" :rating="Math.floor(this.video.rating / this.video.votes) ? Math.floor(this.video.rating / this.video.votes) : 0" :star-size="20" :rounded-corners="true" :border-width="4" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                  <span v-if="message">{{  message }}</span>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot user-info-foot">
              <p>Om du opplever feil/mangler med innholdet kan du sende oss en <a href="mailto:kursportal@cchristoffersen.no">e-post</a></p>
            </footer>
          </div>
        </div>
    </template>
    
    <script>
    import axios from 'axios'
    import StarRating from 'vue-star-rating'
    import { mapGetters } from 'vuex'

    export default {
        props: ['video'],
        data() {
            return {
              player: null,
              votedRating: 0,
              time: 0,
              message: '',
              mileStoneMessage: '',
              watched_25: false,
              watched_50: false,
              watched_75: false,
            }
        },
        methods: {
          handleCancel() {
            this.$parent.showVideoModal = !this.$parent.showVideoModal
            console.log(this.$parent.showVideoModal)
            document.querySelector('video').pause()
            this.message = '',
            this.mileStoneMessage = '',
            this.clearWatched()
          },
          setRating(rating) {
            axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/videos/vote/' + this.video._id, {vote: rating},
            {
              headers: {
                'Authorization': `Bearer ${this.getToken}`
              }
            })
            .then((response) => {
              if(response.status === 200) {
                this.message = response.data.msg
              }
            })
          },
          onPlayerTimeupdate(event) {
            this.time = Math.floor(event.target.currentTime)
            if(Math.floor(this.time / this.video.duration * 100) === 25 && this.watched_25 === false) {
              this.addMilestone(25)
              this.watched_25 = true
            } else if(Math.floor(this.time / this.video.duration * 100) === 50 && this.watched_50 === false) {
              this.addMilestone(50)
              this.watched_50 = true
            } else if(Math.floor(this.time / this.video.duration * 100) === 75 && this.watched_75 === false) {
              this.addMilestone(75)
              this.watched_75 = true
            }
          },
          async addMilestone(milestone) {
            try {
              const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/videos/progress/' + this.video._id,{
              progress: milestone
              }, {
                headers: {
                  'Authorization': `Bearer ${this.getToken}`
                }
              })
              if(response.status == 200 && response.data) {
                this.mileStoneMessage = response.data.msg
              }
            } catch(err) {
              console.log(err)
            }
          },
          clearWatched() {
            this.watched_25 = false
            this.watched_50 = false
            this.watched_75 = false
          }
        },
        mounted() {
          this.player = this.$refs.videoPlayer
        },
        
        components: {
          StarRating
        },
        computed: {
          ...mapGetters([
            'getToken'
          ])
        }
    }
    </script>
    
    <style scoped>
        label {
            font-weight: bold;
        }
        .star {
          margin: 0 2px!important;
        }

        .modal-card-title {
            font-weight: bold;
        }

        a {
          color: #414141!important;
        }

        a:hover {
          text-decoration: underline;
        }

        @media only screen and (max-width: 768px) {
          .modal-card {
            width: 85%;
          }

          .modal-card-title {
            font-size: 1.25rem;
          }
        }
    </style>