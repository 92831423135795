import axios from 'axios'

export function validatePassword(password) {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
    if(password.match(regex)) {
        return 1
    } else return -1
}

export function getFileType(fileName) {
    const regex = /(?:\.([^.]+))?$/
    const fileType = regex.exec(fileName)[1]
    return fileType
}

export async function checkForCookie(token) {
    try {
        const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/auth/verify/' + token)
        if(response.status == 200) {
            return response.data
        }
    } catch(err) {
        console.log(err)
        return -1
    }
}
