import { createStore } from 'vuex'

const store = createStore({
    state: {
        isLoggedIn: null,
        token: null,
        role: null,
        user: {
            name: null,
            email: null,
            position: null,
        }
    },
    mutations: {
        setIsLoggedIn(state) {
            state.isLoggedIn = true
        },
        setToken(state, token) {
            state.token = token.token
        },
        setRole(state, payload) {
            state.role = payload.role
        },
        setUser(state, payload) {
            state.user.email = payload.email
            state.user.name = payload.name
            state.user.position = payload.position
        }
    },
    actions: {
    },
    getters: {
        getLoggedInState(state) {
            return state.isLoggedIn
        },
        getRole(state) {
            return state.role
        },
        getToken(state) {
            return state.token
        },
        getUser(state) {
            return state.user
        }
    }
})

export default store