<template>
<div class="register-wrapper">
    <div class="register-card card p-6">
        <div class="card-content">
            <h3 class="title is-3">Registrer bruker</h3>
            <form @submit.prevent="handleSubmit()">
                <div class="field">
                    <label class="label">Navn</label>
                    <div class="control has-icons-left has-icons-right">
                        <input required v-model="name" class="input" type="text" placeholder="Navn">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="fa-solid fa-user" />
                        </span>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Brukerinfo</label>
                    <div class="control has-icons-left has-icons-right">
                        <input required v-model="email" class="input" type="email" placeholder="E-post">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="fa-solid fa-envelope" />
                        </span>
                    </div>
                    <div class="control has-icons-left has-icons-right mt-3">
                        <input required v-model="password" class="input" type="password" placeholder="Passord">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="fa-solid fa-lock" />
                        </span>
                    </div>
                    <div class="mt-2 has-text-centered">
                        <p style="font-size: 12px;">(Må være mellom 6 og 20 karakterer og inneholde ett tall, en stor- og en liten bokstav)</p>
                    </div>
                </div>
                <div class="field">
                    <label class="label">Firma</label>
                    <div class="control has-icons-left has-icons-right">
                        <input required v-model="company" class="input" type="text" placeholder="Firma">
                        <span class="icon is-small is-left">
                            <font-awesome-icon icon="fa-solid fa-building" />
                        </span>
                    </div>
                </div>
                <label class="label">Stilling</label>
                <div class="select is-dark">
                    <select v-model="position" required name="playlist">
                        <option disabled value="">Velg stilling</option>
                        <option>Lakkerer</option>
                        <option>Formann</option>
                        <option>Oppretter</option>
                        <option>Administrasjon</option>
                        <option>Lærling</option>
                    </select>
                </div>
                <div class="term-container">
                    <label class="checkbox mt-4">
                    <input v-model="acceptedTerms" required type="checkbox">
                    Jeg godtar bruksbetingelsene for <a href="/terms">denne portalen</a>
                    </label>
                </div>
                <button class="button is-dark mt-2 mb-2 register-btn">Registrer bruker</button>
                <div v-if="message" class="notification" :class="message.className">
                    <p>
                        {{ message.content }}
                    </p>
                </div>
                <div class="container links-login my-2">
                <a href="/">Allerede bruker? Logg inn</a>
                <a href="/forgot">Glemt passord</a>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import { validatePassword } from '../functions'
export default {
    data() {
        return {
            name: '',
            email: '',
            password: '',
            company: '',
            position: '',
            acceptedTerms: false,
            message: ''
        }
    },
    methods: {
        async handleSubmit() {
            if(validatePassword(this.password) === 1) {
                try {
                    const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/users/register', {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    company: this.company,
                    position: this.position,
                    acceptedTerms: this.acceptedTerms
                })
                this.message = response.data.message
                } catch(err) {
                    console.log(err)
                }
            } else {
                this.message = {
                content: 'Passordet ditt oppfyller ikke kravene. Vi krever et passord mellom 6 og 20 karakterer. Minst ett tall, en stor- og en liten bokstav.',
                className: 'is-warning'
                }
            }
        },
    }
}
</script>
<style scoped>

.register-wrapper {
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('@/assets/images/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.register-card {
    border-right: 10px solid #414141;
    width: 600px;
}

.register-btn {
    width: 100%;
}

a {
    color: black!important;
}

a:hover {
    text-decoration: underline;
}

.links-login {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: 0;
        font-size: 12px;
}


</style>