import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import 'bulma/css/bulma.css'
const AWS = require('aws-sdk')
import axios from 'axios'
import VueAxios from 'vue-axios'
import Oruga from '@oruga-ui/oruga-next';
import '@oruga-ui/oruga-next/dist/oruga.css';
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import store from './store/store'
import VueCookies from 'vue-cookies'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { 
    faUserSecret,
    faExclamationTriangle,
    faSearch,
    faUpload,
    faChartBar,
    faCheck,
    faTrophy,
    faStar,
    faUser,
    faEnvelope,
    faLock,
    faBuilding,
    faSpinner,
    faEye,
    faHourglass,
    faInbox,
    faPlus,
    faTrash,
    faWindowClose,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'

import { 
    faSnapchat,
    faInstagram,
    faFacebookF
} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faExclamationTriangle)
library.add(faSearch)
library.add(faUpload)
library.add(faChartBar)
library.add(faCheck)
library.add(faSnapchat)
library.add(faInstagram)
library.add(faFacebookF)
library.add(faTrophy)
library.add(faStar)
library.add(faUser)
library.add(faEnvelope)
library.add(faLock)
library.add(faBuilding)
library.add(faSpinner)
library.add(faEye)
library.add(faHourglass)
library.add(faInbox)
library.add(faPlus)
library.add(faTrash)
library.add(faWindowClose)
library.add(faTimes)

createApp(App)
.use(router)
.use(VueCookies, {
    secure: true
})
.use(VueAxios, axios)
.use(Oruga)
.use(store)
.use(VueVideoPlayer)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')
