<template>
    <div v-if="notification.message" :class="[notification.className]" class="notification is-primary mb-0">
    <button @click="wipeNotification()" class="delete"></button>
        {{ notification.message }}
    </div>
    <AdminNavigation />
    <div class="dashboard">
        <div class="columns is-centered">
            <div class="column is-half has-text-centered py-6">
                <img src="https://www.cchristoffersen.no/wp-content/uploads/2018/08/cchristoffersen_logo.png" alt="C. Christoffersen Logo">
            </div>
        </div>
        <div class="columns mx-2 tile-container">
            <div class="column is-4 tile has-text-centered videos-tile is-flex-direction-row is-justify-content-center">
                <div>
                    <h3 class="title is-4">Videoer</h3>
                    <p class="video-count">{{ stats.currentVideos}}</p>
                </div>
            </div>
            <div class="column is-4 tile has-text-centered guides-tile is-flex-direction-row is-justify-content-center">
                <div>
                    <h3 class="title is-4">Veiledninger</h3>
                    <p class="video-count">{{ stats.currentGuides}}</p>
                </div>
            </div>
        </div>
        <div class="columns mx-2 tile-container">
            <div class="column is-4 tile users-tile has-text-centered is-flex-direction-row is-justify-content-center">
                <div>
                <h3 class="title is-4">Brukere</h3>
                <p class="user-count">{{ stats.currentUsers}}</p>
                </div>
            </div>
            <div class="column is-4 tile views-tile has-text-centered is-flex-direction-row is-justify-content-center">
                <div>
                <h3 class="title is-4">Visninger</h3>
                <p class="view-count"> <span style="color: white; font-size: 20px; font-weight: bold;">{{ stats.currentViews[2]}}</span> - <span style="font-size: 15px;">{{ stats.currentViews[1]}}</span> - <span style="font-size: 12px;">({{ stats.currentViews[0]}})</span></p>
                </div>
            </div>
        </div>
        <div class="columns tools my-4 is-mobile">
            <div class="column is-3 mx-2 search-field">
                <input v-model="this.searchTerm" @input="startSearch(searchTerm)" class="input search" type="text" placeholder="Søk etter navn/firma"/>
                <button class="button is-dark">
                    <font-awesome-icon style="color: white;" icon="fa-solid fa-search" />
                </button>
                <button v-if="this.searchTerm != ''" @click="clearSearchField()" class="button is-danger">Tøm</button>
            </div>
            <div @click="showUsersWithoutAccess = !showUsersWithoutAccess" class="mx-2 users-without-access">
                <p v-if="users.filter(user => user.access == false).length != 0" class="user-no-access-nmb has-text-centered">{{ users.filter(user => user.access == false).length
                
                }}</p>
                <button class="button is-dark"><font-awesome-icon icon="fa-solid fa-exclamation-triangle" color="orange" size="2xl"/></button>
            </div>
        </div>
        <div class="user-container column" :class="{loading: userListLoading}">
            <div class="column columns user-properties is-mobile">
                <div class="column is-3">Navn</div>
                <div class="column is-3">E-post</div>
                <div class="column is-2">Firma</div>
                <div class="column is-2">Stilling</div>
                <div class="column is-2">Siste innlogging</div>
            </div>
            <div class="columns is-fullheight">
                <div class="column users">
                    <div v-if="userListLoading" class="columns is-centered loading-container">
                        <div class="column is-half has-text-centered">
                            <font-awesome-icon style="color: white;" icon="fa-solid fa-spinner" class="fa-spin" size="2xl"/>
                        </div>
                    </div>
                    <div v-if="!userListLoading" class="columns user is-mobile" v-for="user in users.slice(0, 15)" @click="activateUserDetailsModal(user._id)" :key="user._id">
                        <div class="column is-3 data">{{ user.name }} <font-awesome-icon v-if="(user.access == false)" style="color: orange;" icon="fa-solid fa-exclamation-triangle fa-sm" /></div>
                        <div class="column is-3 data">{{ user.email }}</div>
                        <div class="column is-2 data">{{ user.company }}</div>
                        <div class="column is-2 data">{{ user.position }}</div>
                        <div class="column is-2 data">{{ new Date(user.lastLogin).toLocaleDateString() != 'Invalid Date' ? new Date(user.lastLogin).toLocaleDateString('en-GB')
                        : new Date(parseInt(user.lastLogin)).toLocaleDateString() != 'Invalid Date' ? new Date(parseInt(user.lastLogin)).toLocaleDateString('en-GB')
                    : 'Ingen' }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column columns functions">
            <div class="column"><button class="function-btn button is-dark">Videoer</button></div>
            <div class="column"><button @click="activateUploadModal" class="function-btn button is-dark">Last opp til mediabiblotek</button></div>
            <div class="column"><button @click="activateCreateMaterialModal" class="function-btn button is-dark">Publiser</button></div>
        </div>
    </div>
    <MediaUploadModal modalClassName="media-upload"/>
    <UserDetailModal :user="this.currentUser" />
    <UserUsageStatsModal :user="this.currentUser" />
    <CreateMaterialModal :class="{'is-active': showCreateMaterial}" />
  </template>
  
  <script>
  // @ is an alias to /src
  import MediaUploadModal from '../components/MediaUpload.vue'
  import CreateMaterialModal from '../components/CreateMaterial.vue'
  import UserDetailModal from '../components/UserDetailModal.vue'
  import UserUsageStatsModal from '../components/UserUsageStatsModal.vue'
  import AdminNavigation from '../components/AdminNavigation.vue'
  import { mapGetters } from 'vuex'
  import axios from 'axios'

  export default {
    name: 'UserDashboard',
    props: {
        user: Object
    },
    data() {
        return {
            file: '',
            progress: '',
            isLoading: '',
            users: [],
            stats: {
                currentUsers: null,
                currentVideos: null,
                currentViews: []
            },
            searchTerm: '',
            username: '',
            currentUser: {},
            userListLoading: true,
            showCreateMaterial: false,
            notification: {
                message: null,
                className: ''
            },
            showUsersWithoutAccess: false
        }
    },
    methods: {
        activateUserDetailsModal(userId) {
            document.querySelector('.user-details').classList.toggle('is-active')
            this.getUserDetails(userId)
        },  
        activateUploadModal() {
            document.querySelector('.file-upload').classList.toggle('is-active')
        },
        activateCreateMaterialModal() {
            this.showCreateMaterial = true
        },

        async getUsers(bool) {
            // includes filter to show only users with property argument == false e.g access 
            this.userListLoading = true
            try {
                const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/users/all', {
                    headers: {
                    'Authorization': `Bearer ${this.getToken}`
                    }
                })
                if(bool) {
                    this.setUsers(response.data.filter(user => user.access == false))
                } else {
                    this.setUsers(response.data)
                }
            } catch(err) {
                console.log(err)
            }
        },
        setUsers(arr) {
            this.users = [...arr]
            this.userListLoading = false
        },
        async getPortalStats() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/counts', {
                    headers: {
                        'Authorization': 'Bearer ' + this.getToken
                    }
                })
                this.setStats(response.data)
            } catch(err) {
                console.log(err)
            }
        },
        setStats(obj) {
                this.stats.currentGuides = obj.guides
                this.stats.currentUsers = obj.users
                this.stats.currentVideos = obj.videos,
                this.stats.currentViews = [...obj.viewCounts]
        },
        startSearch(term) {
            this.userListLoading = true
            fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/users/all', {
                headers: {
                    'Authorization': `Bearer ${this.getToken}`
                }
            })
            .then(response => response.json())
            .then(data => data.filter((user) => {
                return user.company.toLowerCase().includes(term.toLowerCase()) || user.name.toLowerCase().includes(term.toLowerCase())
            }))
            .then(filteredData => this.setUsers(filteredData))
        },
        clearSearchField() {
            this.searchTerm = ''
            this.getUsers()
        },
        getUserDetails(userId) {
            fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/users/' + userId, {
                headers: {
                    'Authorization': 'Bearer ' + this.getToken
                }
            })
            .then(response => response.json())
            .then((data) => this.setCurrentUserObject(data))
        },
        setCurrentUserObject(obj) {
            this.currentUser = obj

        },
        wipeNotification() {
            this.notification.message = ''
            this.notification.className = ''
        }
    },
    watch: {
            showUsersWithoutAccess(newValue, oldValue) {
                if(newValue == true) {
                    this.getUsers('access')
                } else {
                    this.getUsers()
                }
            }
    },
    components: {
        AdminNavigation,
        MediaUploadModal,
        CreateMaterialModal,
        UserDetailModal,
        UserUsageStatsModal
    },
    mounted() {
        this.getUsers(),
        this.getPortalStats()
    },
    computed: {
        ...mapGetters([
            'getToken'
        ])
    }
  }
  </script>
  <style scoped>
    .dashboard {
        margin: 0 25px;
    }

    .users-without-access {
        display: flex;
        align-items: center;
        position: relative;
    }

    .user-no-access-nmb {
        width: 25px;
        height: 25px;
        background-color: red;
        border-radius: 10px;
        color: white;
        z-index: 5;
        position: absolute;
        top: 2px;
        right: -10px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tile-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 15px;
        margin: 0 100px;
        height: 115px;
    }

    .tile {
        margin: 0;
        margin-top: 15px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
    }

    .tile span {
        font-size: 1.5rem;
    }

    .tile .title {
        margin: 0;
        color: white;
    }

    .users-tile {
        background-color: #6454a4;
    }

    .videos-tile {
        background-color: #fc640c;
        gap: 50px;
    }

    .guides-tile {
        background-color: #4a4a4a;
        gap: 50px;
    }
    .views-tile {
        background-color: #83c41c;
    }

    .function-btn {
        width: 100%;
    }

    .user-container {
        border: 1px solid black;
    }
    
    .user-properties {
        background-color: #f0f0f0;
        font-weight: bold;
    }

    .search-field {
        margin: 0;
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .search-field input {
        width: 200px;
    }

    .user:nth-child(even) {
        background-color: #f0f0f0;
    }

    .user:hover {
        cursor: pointer;
        opacity: .9;
    }

    .user-container {
        min-height: 800px;
    }

    .loading-container {
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .users {
        height: 100%;
    }

    .loading {
        background-color: #848484;
        z-index: 500;
    }

    .is-fullheight .users .loading-container {
        min-height: 800px;
    }

    @media only screen and (max-width: 992px) {
        .data {
            font-size: 0.65em;
            overflow: hidden;
        }

        .tile h3 {
            font-size: 15px;
        }

        .user-properties {
            font-size: 0.8em;
        }
    }

    .tools {
        display: flex;
        gap: 75px;
        justify-content: space-between;
    }

    .search-field {
        width: 40%!important;
    }
  </style>
  