<template>
    <div class="register-wrapper">
        <div class="register-card card p-6">
            <div class="card-content">
                <h3 class="title is-3">Glemt passord</h3>
                <form @submit.prevent="handleSubmit()">
                    <div class="field">
                        <label class="label">E-post</label>
                        <div class="control has-icons-left has-icons-right">
                            <input required v-model="email" class="input" type="email" placeholder="E-post">
                            <span class="icon is-small is-left">
                                <font-awesome-icon icon="fa-solid fa-envelope" />
                            </span>
                        </div>
                    </div>
                    <button class="button is-dark mb-2">Tilbakestill passord</button>
                    <div v-if="message" class="notification is-info">
                        {{ message }}
                    </div>
                </form>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import axios from 'axios'
    export default {
        data() {
            return {
            email: '',
            message: ''

            }
        },
        methods: {
            async handleSubmit() {
                try {
                    const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/users/forgot', {
                    email: this.email
                    })
                    this.message = response.data.msg
                } catch(err) {
                    this.message = 'Det har oppstått en feil. Prøv igjen senere, eller ta kontakt med oss'
                    this.className = 'is-danger'
                }
            }
        }
    }
    </script>
    <style scoped>
    .register-wrapper {
        width: 100vw;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('@/assets/images/background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .register-card {
        border-right: 10px solid #414141;
        width: 600px;
    }

    button {
        width: 100%;
    }
    </style>