<template>
     <div v-if="notification.message" :class="[notification.className]" class="notification is-primary">
    <button @click="wipeNotification()" class="delete"></button>
        {{ notification.message }}
    </div>
    <AdminNavigation />
        <div class="files">
                <div class="properties columns file-properties is-mobile">
                    <div class="column thumbnail is-1">Thumbnail</div>
                    <div class="column file-title is-7">Tittel</div>
                    <div class="column size is-2">Størrelse(MB)</div>
                    <div class="column type is-1">Filtype</div>
                    <div class="column type is-1">Handling</div>

                </div>
                <div class="file columns is-3" v-for="file in files" :key="file.ETag">
                    <div v-if="getFileType(file.Key) == 'jpg'" class="column thumbnail is-1"><img class="thumbnail" :src="(this.staticS3Path + file.Key)"/></div>
                    <div v-else class="column is-1"></div>
                    <div class="column file-title is-7">{{ trimFileName(file.Key) }}</div>
                    <div class="column size is-2"> {{ (file.Size / 10000).toFixed() }}</div>
                    <div class="column type is-1"> {{ getFileType(file.Key)}} </div>
                    <div class="column type is-1"><button class="button is-danger is-small" @click="() => { showWarningBox = true, fileName = file.Key}"><font-awesome-icon icon="fa-solid fa-window-close" color="white" size="xl"/></button></div>
                </div>
        </div>
        <WarningBox :message="'Er du sikker på at du vil slette ' + this.fileName + '?'" />
</template>


<script>

import AdminNavigation from '../components/AdminNavigation.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
import WarningBox from '../components/WarningBox.vue'

export default {
    data() {
        return {
            staticS3Path: 'https://cc-vidoeportal.s3.eu-north-1.amazonaws.com/',
            files: [],
            notification: {
                message: '',
                className: ''
            },
            fileName: '',
            showWarningBox: false,
            confirmDeletion: false
        }
    },
    methods: {
        async handleDeleteMedia() {
            const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/bucket/delete/' + this.fileName, {
                headers: {
                    'Authorization': `Bearer ${this.getToken}`
                }
            })
            if(response) {
                this.notification.message = response.data.msg
                this.notification.className = response.data.className
                this.confirmDeletion = false
                this.showWarningBox = false
                this.getAvailableFiles()
            }
        },
        getAvailableFiles() {
            fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/bucket/get', {
                headers: {
                    'Authorization': 'Bearer ' + this.getToken
                }
            })
            .then(response => response.json())
            .then(data => this.setFiles(data))
        },
        setFiles(arr) {
            this.files = [...arr]
        },
        getFileType(fileName) {
            const regex = /(?:\.([^.]+))?$/
            const fileType = regex.exec(fileName)[1]
            return fileType
        },
        trimFileName(fileName) {
            const regex = /(.*)\.[^.]+$/
            const newFileName = fileName.replace(regex, "$1")
            return newFileName
        },
        setImageSrc(key) {
            return this.staticS3Path + key
        },
        wipeNotification() {
            this.notification.message = ''
            thus.notification.className = ''
        }
    },
    mounted() {
        this.getAvailableFiles()
    },
    watch: {
        confirmDeletion(newValue) {
            if(newValue == true) {
                this.handleDeleteMedia()
            }
        }
    },
    components: {
        AdminNavigation,
        WarningBox
    },
    computed: {
        ...mapGetters([
            'getToken'
        ])
    }
}
</script>

<style scoped>

.file .column {
    display: flex;
    align-items: center;
}

.thumbnail {
    height: 100%;
    display: flex;
    align-items: center;
}

.file-properties .column {
    display: flex;
    align-items: center;
}
.files {
    margin: 50px;
}

.file-properties {
        background-color: #f0f0f0;
        font-weight: bold;
}

.thumbnail img {
    height: 25px;
    width: 25px;
}

.file:nth-child(even) {
        background-color: #f0f0f0;
    }

</style>