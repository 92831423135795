<template>
    <div class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title video-edit-title">Gjør endringer på video</p>
            <button @click="handleCancel()" class="delete video-edit-delete" aria-label="close"></button>
          </header>
        <section class="modal-card-body video-edit-body">
            <div>
                <form @submit.prevent="handleSubmit()" method="POST">
                    <div class="column"><label class="label">Tittel</label><input class="input" type="text" v-model="activeVideo.title"></div>
                    <div class="column"><label class="label">Kategori</label><input class="input" type="text" v-model="activeVideo.playlist" ></div>
                    <div class="column"><label class="label">Beskrivelse</label><textarea style="height: 130px;" v-model="activeVideo.description" class="input" type="textarea"></textarea></div>
                    <div class="column"><label class="label">Rating</label><input readonly is-static class="input" type="text" v-model="activeVideo.rating"></div>
                    <div class="column"><label class="label">Visninger</label><input readonly is-static class="input" type="text" v-model="activeVideo.views"></div>
                    <div class="column"><label class="label">Stemmer</label><input readonly is-static class="input" type="text" v-model="activeVideo.votes"></div>
                    <div class="column"><label class="label">URL video</label><input readonly is-static class="input" type="text" v-model="activeVideo.url"></div>
                    <div class="column"><label class="label">Arkivert</label>
                    <o-field>
                        <o-switch variant="success" v-model="activeVideo.archived">
                        </o-switch>
                    </o-field>
                    </div>
                    <div class="column"><label class="label">URL thumbnail</label><input readonly is-static class="input" type="text" v-model="activeVideo.thumbnail"></div>
                    <div class="column"><label class="label">ID</label><input readonly is-static class="input" type="text" v-model="activeVideo._id"></div>
                </form>
            </div>
        </section>
          <footer class="modal-card-foot">
            <button @click="handleSubmit()" type="submit" form="edit" class="button is-success">Lagre</button>
            <button @click="handleCancel()" class="button">Avbryt</button>
            <button @click="handleDelete(activeVideo._id)" class="button is-danger is-pulled-right">Slette</button>
          </footer>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                id: this.activeVideo._id,
                showWarningBox: false
            }
        },
        props: ['activeVideo'],
        methods: {
            async handleSubmit() {
                try {
                    const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/videos/edit/' + this.id, this.activeVideo, {
                  headers: {
                    'Authorization': `Bearer ${this.$parent.getToken}`,
                  },
                })
                if(response.data) {
                    this.$parent.showVideoEdit = false
                    this.$parent.notification.message = response.data.msg
                    this.$parent.notification.className = response.data.className
                }
                } catch(err) {
                    console.log(err)
                    this.$parent.showVideoEdit = false
                    this.$parent.notification.message = err.response.data.msg
                    this.$parent.notification.className = err.response.data.className
                }
            },
            async handleDelete(id) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/videos/delete/' + id, {
                    headers: {
                        'Authorization': `Bearer ${this.$parent.getToken}`
                    }})

                    if(response.data) {
                    this.$parent.showVideoEdit = false
                    this.$parent.notification.message = response.data.msg
                    this.$parent.notification.className = response.data.className
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.$parent.fetchData('videos')
                }
                } catch(err) {
                    console.log(err)
                    this.$parent.showVideoEdit = false
                    this.$parent.notification.message = err.response.data.msg
                    this.$parent.notification.className = err.response.data.className
                }
            },
            handleCancel() {
                this.$parent.showVideoEdit = false
            }
        },
    }


</script>