<template>
<div v-if="notification.message" :class="[notification.className]" class="notification is-primary my-0">
<button @click="wipeNotification()" class="delete"></button>
    {{ notification.message }}
</div>
<AdminNavigation />
<div class="wrapper">
    <div class="columns is-centered">
        <div class="column">
            <h3 class="title is-4">Innhold</h3>
            <div class="columns m-4">
                <div @click="videos = !videos" :class="{ active:videos }" class="column is-2 filter has-text-centered m-2">Videoer</div>
                <div @click="videos = !videos" :class="{ active:!videos }" class="column is-2 filter has-text-centered m-2">Veiledninger</div>
            </div>
            <div class="search-container columns is-centered my-4">
                <input v-model="title" class="input column is-4" type="text" placeholder="Søk"/>
                <button class="button is-dark ml-4"><font-awesome-icon style="color: white" icon="fa-solid fa-search"/></button>
            </div>
            <div class="content-container columns is-multiline is-centered">
                <div @click="setActive(entry._id)" v-for="entry in content" :key="entry._id" class="column is-5 content-card">
                    <div class="columns">
                        <div class="column is-2">
                            <img class="m-4" :src="entry.thumbnail"/>
                        </div>
                        <div class="column is-3 content-title has-text-centered"><p>{{ entry.title}}</p></div>
                        <div v-if="videos" class="column is-2 content-length">
                            <font-awesome-icon class="mr-2" style="color: #414141;" icon="fa-solid fa-hourglass" size="xl"/> {{ (entry.duration / 60).toFixed() }}:{{ entry.duration % 60 < 10 ? '0' + entry.duration % 60 : entry.duration % 60}}
                        </div>
                        <div class="column is-2 content-views">
                            <font-awesome-icon class="mr-2" style="color: #414141;" icon="fa-solid fa-eye" size="xl"/> {{ entry.views}}
                        </div>
                        <div v-if="videos" class="column is-1 content-rating">
                            <font-awesome-icon class="mr-2" style="color: orange;" icon="fa-solid fa-star" size="xl"/> {{ isNaN((entry.rating / entry.votes)) ? 0 : (entry.rating / entry.votes).toFixed(1)}}
                        </div>
                        <div class="column is-2 content-change-btn has-text-centered"><button class="button change-btn is-warning is-small">Endre</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-wrapper">
        <VideoEditModal v-if="showVideoEdit" :activeVideo="this.active" :class="{ 'is-active': showVideoEdit}"/>
        <GuideEditModal v-if="showGuideEdit" :activeGuide="this.active" :class="{ 'is-active': showGuideEdit}"/>
    </div>
</div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import AdminNavigation from '../components/AdminNavigation.vue'
import VideoEditModal from '../components/VideoEditModal.vue'
import GuideEditModal from '../components/GuideEditModal.vue'

export default {
    data() {
        return {
            active: null,
            videos: true,
            content: [],
            title: '',
            showVideoEdit: false,
            showGuideEdit: false,
            notification: {
                className: null,
                message: null
            }
        }
    },
    methods: {
        fetchData(content) {
            fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}/api/v1/${content}/all`, {
                headers: {
                    'Authorization': `Bearer ${this.getToken}`
                }
            })
            .then(response => response.json())
            .then(data => this.setContent(data))
        },
        setContent(arr) {
            this.content = [...arr]
        },
        async setActive(id) { 
            const content = this.videos ? 'videos' : 'guides'
            try {
                const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}/api/v1/${content}/${id}`,{
                headers: {
                    'Authorization': `Bearer ${this.getToken}`
                }
                })
                this.active = response.data
                if(this.videos) {
                    this.showVideoEdit = true
                } else {
                    this.showGuideEdit = true
                }
            } catch(err) {
                console.log(err)
            }
        },
        wipeNotification() {
            this.notification.message = null,
            this.notification.className = ''
        }
    },
    mounted() {
        this.fetchData('videos')
    },
    watch: {
        videos(value) {
            if(value == false) {
                this.fetchData('guides')
            } else {
                this.fetchData('videos')
            }
        }
    },
    components: {
        AdminNavigation,
        GuideEditModal,
        VideoEditModal,
    },
    computed: {
        ...mapGetters([
            'getToken'
        ])
    }
}
</script>

<style scoped>
.wrapper {
    margin: 50px;
}
.content-card {
    background-color: #f0f0f0;
    border-radius: 15px;
    color: #414141;
    margin: 25px;
}
.content-card img {
    width: 150px;
    height: auto;
    border-radius: 25px;
}
.content-card:hover {
    cursor: pointer;
    border: 0.03em solid #414141;
}   
.content-change-btn {
    padding: 0!important;
}

.content-rating {
    display: flex;
    flex-direction: row;
}

.content-card .column {
    margin: auto;
}

.filter {
    color: #101010;
    font-size: 22px;
}

.filter:hover {
    cursor: pointer;
    font-weight: bold;
    color: #414141;
    border: 2px solid #101010;
    border-radius: 15px;
}

.active {
    border: 2px solid #101010;
    border-radius: 15px;
}

.change-btn {
    margin: 0!important;
}

.content-length, .content-views, .content-rating {
    padding: 0!important;
}

@media only screen and (max-width: 992px) {

    .search-container {
        display: flex;
        justify-content: space-around;
    }

    .content-card .columns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;
        padding-bottom: 15px;
    }
}
</style>