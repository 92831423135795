<template>
    <div class="modal user-details">
          <div class="modal-background"></div>
          <div class="modal-card user-info-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Brukerinfo</p>
              <button @click="handleCancel()" class="delete" aria-label="close"></button>
            </header>
            <section class="modal-card-body">
              <div class="mx-2 is-dark user-info-body is-flex-direction-column">
                <label for="user-id">ID</label>
                <input type="text" id="user-id" class="mb-4 input user-id" name="user-id" v-model="user._id" readonly>
                <label for="user-name">Navn</label>
                <input type="text" id="user-name" class="mb-4 input user-name" name="user-name" v-model="user.name">
                <label for="user-email">E-post</label>
                <input type="text" id="user-email" class="mb-4 input user-email" name="user-email" v-model="user.email">
                <label for="user-company">Firma</label>
                <input type="text" id="user-company" class="mb-4 input user-company" name="user-company" v-model="user.company">
                <h3 class="title is-6 mb-0">Stilling</h3>
                <div class="select my-2 mb-4 is-dark">
                  <select v-model="user.position" class="user-position-select">
                      <option value="Lakkerer">Lakkerer</option>
                      <option value="Formann">Formann</option>
                      <option value="Oppretter">Oppretter</option>
                      <option value="Administrasjon">Administrasjon</option>
                      <option value="Lærling">Lærling</option>
                  </select>
                </div>
              </div>
              <div class="mx-2">
                <h3 class="title is-6 mb-2">Tilgang</h3>
                <o-field>
                  <o-switch variant="success" v-model="user.access"></o-switch>
                </o-field>
              </div>
              <div class="mx-2"><h3 class="title is-6 mb-2">Bruksstatistikk</h3>
                <button @click="activateUserUsageStatsModal()" class="button is-dark"><font-awesome-icon style="color: white;" icon="fa-solid fa-chart-bar" /></button>
                <div class="last-logon"></div>
              </div>
            </section>
            <footer class="modal-card-foot user-info-foot">
                <button class="button is-success" @click="handleSubmit(user)">Lagre</button>
                <button class="button" @click="handleCancel()">Avbryt</button>
                <button class="button is-danger" @click="showWarningBox = true">Slett</button>
            </footer>
          </div>
        </div>
        <div class="modal" :class="{ 'is-active': showWarningBox}">
          <div class="modal-background"></div>
            <div class="modal-content">
              <div class="card">
                <div class="card-content">
                  <div class="content has-text-centered">
                    <p class="is-size-5">Er du sikker på du vil slette brukeren {{  user.email }}?</p>
                    <button @click="handleDelete" class="button mx-4">Ja</button>
                    <button @click="showWarningBox = false" class="button is-danger">Avbryt</button>
                  </div>
                  </div>
                </div>
              </div>
        </div>
    </template>
    
    <script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'

    export default {
        props: ['user'],
        data() {
            return {
              showWarningBox: false,
              deletionError: '',
            }
        },
        methods: {
            activateUserUsageStatsModal() {
            document.querySelector('.user-usage-stats').classList.toggle('is-active')
            },
            handleCancel() {
                document.querySelector('.user-details').classList.toggle('is-active')
            },
            async handleSubmit(user) {
              try {
                const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/users/edit',user
                ,{
                  headers: {
                    'Authorization': `Bearer ${this.getToken}`,
                  },
                })
                this.$parent.notification.message = 'Bruker oppdatert'
                this.$parent.notification.className = 'is-sucess',
                window.scrollTo({ top: 0, behavior: 'smooth' });
                document.querySelector('.user-details').classList.toggle('is-active')

              } catch(err) {
                console.log(err)
              }
            
            },
            async handleDelete() {
              try {
                const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/users/delete/' + this.user.email, {
                  headers: {
                    'Authorization': `Bearer ${this.getToken}`
                  }
                })
                
                  this.showWarningBox = false
                  this.$parent.notification.message = response.data.msg,
                  this.$parent.notification.className = response.data.className,
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  document.querySelector('.user-details').classList.toggle('is-active')
                  this.$parent.getUsers()
                  this.$router.push('/dashboard')
              } catch(err) {
                console.log(err)
              }
            },

        },
        components: {
        },
        computed: {
          ...mapGetters([
            'getToken'
          ])
        }
    }
    </script>
    
    <style scoped>
        label {
            font-weight: bold;
        }
    
        .modal-card-title {
            font-weight: bold;
        }
    </style>