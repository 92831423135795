<template>
    <EndUserNav :class="'p-0'" />
    <div class="wrapper columns p-0">
        <div class="navbar-menu mobile mt-2 has-text-centered">
            <div class="image-container">
                <img src="@/assets/images/cc_logo.png" alt="CC Logo" />
            </div>
            <div class="btn-container is-flex is-align-content-center is-justify-content-center">
                <button @click="showVideoFilters = true" class="button is-dark is-medium">Sorter etter kategori</button>
            </div>
            <div v-show="showVideoFilters" class="mobile-filter-container">
                <div class="close-icon-container">
                <font-awesome-icon @click="showVideoFilters = false" style="color: #414141;" class="mx-2 is-pulled-left ml-6 mt-4 close" icon="fa-solid fa-times" size="2xl"/>
                </div>
                <div class="filter-container my-6">
                    <ul class="filters">
                    <li @click="getGuides()" class="filter py-2">Alle</li>
                    <li @click="getGuides(filters[1])" class="filter py-2">Forarbeid</li>
                    <li @click="getGuides(filters[2])" class="filter py-2">Lakkering</li>
                    <li @click="getGuides(filters[3])" class="filter py-2">Etterarbeid</li>
                    <li @click="getGuides(filters[4])" class="filter py-2">Skade</li>
                    <li @click="getGuides(filters[5])" class="filter py-2">Vedlikehold</li>
                </ul>
                </div>
            </div>
        </div>
        <div class="side-bar column is-3 p-0 m-0">
            <div class="menu-header">
            <div class="image-container">
                <img src="@/assets/images/cc_logo.png" alt="CC Logo" />
            </div>
            </div>
            <div class="menu-body">
                <h3 class="title is-3 has-text-centered">CC Kursportal</h3>
                <ul class="filters">
                    <li @click="getGuides()" class="filter py-2">Alle</li>
                    <li @click="getGuides(filters[1])" class="filter py-2">Forarbeid</li>
                    <li @click="getGuides(filters[2])" class="filter py-2">Lakkering</li>
                    <li @click="getGuides(filters[3])" class="filter py-2">Etterarbeid</li>
                    <li @click="getGuides(filters[4])" class="filter py-2">Skade</li>
                    <li @click="getGuides(filters[5])" class="filter py-2">Vedlikehold</li>
                </ul>
            </div>
            <div class="menu-footer">
                <div class="social-icons my-6">
                    <a href="http://instagram.com/ccbillakk"><font-awesome-icon style="color: black;" class="mx-2" icon="fa-brands fa-instagram" size="xl"/></a>
                    <a href="https://www.facebook.com/groups/270898140165225/"><font-awesome-icon style="color: black;" class="mx-2" icon="fa-brands fa-facebook-f" size="xl"/></a>
                    <a href="https://www.snapchat.com/add/ccbillakk"><font-awesome-icon style="color: black;" class="mx-2" icon="fa-brands fa-snapchat" size="xl"/></a>
                </div>
                <div class="btn-container has-text-centered">
                <a href="https://www.ccbillakk.no/kontakt-oss" target="_blank"><button class="button is-medium is-dark contact-us">Kontakt oss</button></a>
                </div>
            </div>
        </div>
        <div class="content column is-9 p-0">
            <div class="columns is-multiline p-4 is-centered">
                <div v-for="guide in guides" class="column is-5 guide" :key="guide._id">
                    <a @click="addViewToGuideAndRedirect(guide._id)">
                        <div class="list-item">
                            <div class="thumbnail-container is-16by9" style="height: 72px; width:128px;">
                                <img style="height: 100%;" :src="guide.thumbnail" alt="Guide thumbnail">
                            </div>
                            <div class="text-content-container m-2">
                                <div class="heading">
                                    {{guide.title}}
                                </div>
                                <p class="subtitle is-6">
                                    {{guide.description}}
                                </p>
                                <div class="meta-container">
                                    <div class="tag">
                                    {{guide.playlist}}
                                    </div>
                                    <div class="mx-2 tag date-created">
                                    {{ new Date(parseInt(guide.dateCreated)).toLocaleDateString()}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
            </div>
        </div>
</div>
</div>
</template>

<script>
import axios from 'axios'
import EndUserNav from '../components/EndUserNav.vue'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            guides: [],
            currentFilter: '',
            filters: ['','forarbeid','lakkering','etterarbeid','skade', 'vedlikehold'],
            showVideoFilters: false
        }
    },
    methods: {
        async getGuides(category) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/guides/all', {
                    headers: {
                    'Authorization': `Bearer ${this.getToken}`
                    }
                })
                if(category) {
                    this.setGuides(response.data.filter((guide) => { 
                    return guide.playlist === category
                }))
                } else {
                    this.setGuides(response.data)
                }
            } catch(err) {
                console.log(err)
            }
        },
        setGuides(arr) {
            this.guides = [...arr.filter((guide) => guide.apprenticeOnly !== true)]
        },
        addViewToGuideAndRedirect(id) {
            axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/guide/addView/' + id, {
                headers: {
                    'Authorization': `Bearer ${this.getToken}`
                }

            })
            this.$router.push(`/pdf-viewer/${id}`)
        }
    },
    components: {
        EndUserNav
    },
    mounted() {
        this.getGuides()
    },
    computed: {
        ...mapGetters([
            'getToken',
            'getRole',
            'getUser'
        ])
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    body, html {
        margin: 0;
        padding: 0;
        background-color: #757575;
    }

    .wrapper {
        z-index: -100;
    }

    .wrapper {
        height: 100vh;
        width: 100vw;
    }
    .side-bar {
        font-family: Roboto, sans-serif;
        background-image: linear-gradient(405deg, rgba(75, 75, 75, 1) 6%, rgb(107, 107, 107) 100%);
        color: black;
    }
    .menu-body {
        padding: 24px;
        font-size: 17px;
        font-weight: 500;
        margin: 0 25px;
    }

    .filter {
        font-size: 15px;
        color: black;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.03em;
    }

    .filter:hover {
        color: #989898;
        cursor: pointer;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-decoration-color: #989898;
        text-underline-offset: 7px;

    }

    .content {
        background-color: #757575;
        padding: 50px;
        height: 100%;
        overflow:scroll;
    }

    .content::-webkit-scrollbar {
    display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }

    .title {
        color: black;
    }

    .menu-footer {
        margin: 0 25px;
        padding-left: 24px;
        padding-right: 24px;
    }

    .contact-us{
        width: 100%;
        border-radius: 50px;
    }

    .guide {
    background-color: #fff;
    margin: 15px 20px;
    border-radius: 15px;
}

.guide .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.guide figure {
   object-fit: cover;
}

.guide .thumbnail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 128px;
}

.guides {
    justify-content: center;
}

.guides a {
    text-decoration: none!important;
}

.guide:hover {
    cursor: pointer;
    transform: scale(0.995);
}

.text-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
}

.meta-container {
    display: flex;
    flex-direction: row;
}

.date-created {
    background-color: black!important;
    color: white!important;
}

.mobile {
        background-color: #757575;
    }

    .mobile-filter-container {
        background-color: white;
        border-radius: 15px;
        height: 450px;
        position: fixed;
        bottom: 0;
        z-index: 100;
        width: 100%;
    }

    .close:hover {
        cursor: pointer;
    }
    @media only screen and (min-width: 993px)  {
        .mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 992px) {
        .mobile {
            display: block;
        }
        .media-content .title {
            overflow:hidden;
        }

        .side-bar {
            display: none;
        }

        .content {
            width: 100%!important;
        }

        .menu-footer {
            padding-bottom: 25px;
        }
    }


</style>