import store from '../store/store'
import { checkForCookie } from '../functions'

export const routeGuard = async (to, from, next) => {
        if(!$cookies.get('token')) {
                next('/')
        } else {
            const decoded = await checkForCookie($cookies.get('token'))
            if(decoded == -1) {
                next('/')
            } else {
                setState(decoded)
                next()
            }
        }
}

export const adminRouteGuard = async (to, from, next) => {
    if(!$cookies.get('token')) {
        next('/')
    } else {
        const decoded = await checkForCookie($cookies.get('token'))
        if(decoded == -1) {
            next('/')
        } else {
            setState(decoded)
            if(store.getters.getLoggedInState && store.getters.getRole === 'admin') {
                next()
            } else {
                next('/')
            }
        }
    }
}

export const apprenticeRouteGuard = async (to, from, next) => {
    if(!$cookies.get('token')) {
        next('/')
    } else {
        const decoded = await checkForCookie($cookies.get('token'))
        if(decoded == -1) {
            next('/')
        } else {
            setState(decoded)
            if(store.getters.getLoggedInState && store.getters.getRole === 'admin' || store.getters.getUser.position === 'Lærling') {
                next()
            } else {
                next('/')
            }
        }
    }
}

async function setState(decodedCookie) {
        store.commit('setUser', {
            name: decodedCookie.name,
            email: decodedCookie.user,
            position: decodedCookie.position
        })
        store.commit('setIsLoggedIn')
        store.commit('setToken', {
            token: $cookies.get('token')
        })
        if(decodedCookie.admin === true) {
            store.commit('setRole', {
                role: 'admin'
            })
        }
}



