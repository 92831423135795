<template>
    <div v-if="error" class="notification is-warning mb-0">
        <button @click="clearError()" class="delete"></button>
        {{ error }}
    </div>
    <div class="login-container">
        <div class="form-container">
        <form class="login-form m-4">
            <div class="columns is-flex-direction-column">
            <div class="column is-12 has-text-centered"><img class="mb-4" src="https://www.cchristoffersen.no/wp-content/uploads/2018/08/cchristoffersen_logo.png" /></div>
            <div class="field column is-12 my-0 py-2">
            <div class="control has-icons-left">
              <input v-model="this.username" class="input is-medium is-dark" type="email" name="email" placeholder="E-post" autocomplete="on">
              <span class="icon is-small is-left">
                <font-awesome-icon icon="fa-solid fa-envelope"/>
              </span>
            </div>
          </div>
          <div class="field column is-12 my-0 py-0">
            <div class="control has-icons-left">
              <input v-model="this.password" class="input is-medium is-dark" type="password" name="password" placeholder="Passord" autocomplete="off">
              <span class="icon is-left">
                <i class="fas fa-lock fa-sm"></i>
                <font-awesome-icon icon="fa-solid fa-lock"/>
              </span>
            </div>
          </div>
          <div class="links-login column is-12 mb-0 pb-0">
                <div class="columns is-mobile m-0 p-0">
                    <div class="column m-0 p-0">
                        <a href="/register">Registrer en bruker</a>
                    </div>
                    <div class="column m-0 p-0">
                        <a class="is-pulled-right" href="/forgot">Glemt passord</a>
                    </div>
                </div>
            </div>
            <div class="column is-12">
            <button @click.prevent="handleSubmit" class="button is-dark">Logg inn</button>
            </div>
        </div>
        </form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            error: ''
        }
    },
    methods: {
        async handleSubmit() {
            if(this.username === '' || this.password === '') {
                this.error = 'Feltene kan ikke være blanke.'
                return
            }
            try {
               const response = await fetch(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/auth/login', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: this.username, 
                    password: this.password
                })
               })
               const data = await response.json()
               if(data.error) {
                this.error = data.error
                return
               }
               if(!data?.user.access) {
                this.error = 'Du har ikke tilgang til å logge inn.'
                return
               } else {
                $cookies.set('token', data.token)
                this.$store.commit('setUser', {
                    name: data.user.name,
                    email: data.user.email,
                    position: data.user.position
                })
                this.$store.commit('setIsLoggedIn')
                this.$store.commit('setToken', {
                    token: data.token
                })
                if(data.user.admin === true) {
                    this.$store.commit('setRole', {
                        role: 'admin'
                    })
                    this.$router.push('/dashboard')
                } else {
                    this.$router.push('/content')
                }
               }
            } catch(err) {
                console.log(err)
            }
        },
        clearError() {
            this.error = ''
        }
    },
}

</script>

<style scoped>
    .login-container {
        width: 100vw;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: url('@/assets/images/background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .links-login {
        font-size: 12px;
    }

    .links-login p:hover {
        cursor: pointer;
        text-decoration: underline;
    }


    .login-form {
        width: 400px;
        height: 350px;
        background: #f0f0f0;
        padding: 25px;
        border: 0.5px solid black;
        border-radius: 10px;
    }


    .login-form input:focus {
        border-color: black;
        box-shadow: none;
    }

    a {
    color: black!important;
    }

    a:hover {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
    }
</style>