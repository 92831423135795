<template>
    <div class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title video-edit-title">Gjør endringer på video</p>
            <button @click="handleCancel()" class="delete video-edit-delete" aria-label="close"></button>
          </header>
        <section class="modal-card-body video-edit-body">
            <div>
                <form @submit.prevent="handleSubmit()" method="POST">
                    <div class="column"><label class="label">Tittel</label><input class="input" type="text" v-model="activeGuide.title"></div>
                    <div class="column"><label class="label">Kategori</label><input class="input" type="text" v-model="activeGuide.playlist" ></div>
                    <div class="column"><label class="label">Kun lærlinger</label>
                        <o-field>
                            <o-switch variant="success" v-model="activeGuide.apprenticeOnly"></o-switch>
                        </o-field>
                    </div>
                    <div class="column"><label class="label">Publisert</label><input readonly is-static :value="new Date(parseInt(activeGuide.dateCreated)).toLocaleDateString()" class="input" type="text"></div>
                    <div class="column"><label class="label">Beskrivelse</label><textarea style="height: 130px;" v-model="activeGuide.description" class="input" type="textarea"></textarea></div>
                    <div class="column"><label class="label">Visninger</label><input readonly is-static class="input" type="text" v-model="activeGuide.views"></div>
                    <div class="column"><label class="label">URL video</label><input readonly is-static class="input" type="text" v-model="activeGuide.url"></div>
                    <div class="column"><label class="label">URL thumbnail</label><input readonly is-static class="input" type="text" v-model="activeGuide.thumbnail"></div>
                    <div class="column"><label class="label">ID</label><input readonly is-static class="input" type="text" v-model="activeGuide._id"></div>
                </form>
            </div>
        </section>
          <footer class="modal-card-foot">
            <button @click="handleSubmit()" type="submit" form="edit" class="button is-success">Lagre</button>
            <button @click="handleDeleteGuide(activeGuide._id)" class="button is-danger">Slette</button>
            <button @click="handleCancel()" class="button">Avbryt</button>
          </footer>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                id: this.activeGuide._id
            }
        },
        props: ['activeGuide'],
        methods: {
            async handleSubmit() {
                try {
                    const response = await axios.post(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/guides/edit/' + this.id, this.activeGuide, {
                  headers: {
                    'Authorization': `Bearer ${this.$parent.getToken}`,
                  },
                })

                if(response.data) {
                    this.$parent.showGuideEdit = false
                    this.$parent.notification.message = response.data.msg
                    this.$parent.notification.className = response.data.className
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.$parent.fetchData('guides')
                }
                } catch(err) {
                    console.log(err)
                    this.$parent.showGuideEdit = false
                    this.$parent.notification.message = err.response.data.msg
                    this.$parent.notification.className = err.response.data.className
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            },
            async handleDeleteGuide(id) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/guides/delete/' + id, {
                    headers: {
                        'Authorization': `Bearer ${this.$parent.getToken}`
                    }})
                    if(response.data) {
                    this.$parent.showGuideEdit = false
                    this.$parent.notification.message = response.data.msg
                    this.$parent.notification.className = response.data.className
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    this.$parent.fetchData('guides')
                }
                } catch(err) {
                    console.log(err)
                    this.$parent.showGuideEdit = false
                    this.$parent.notification.message = err.response.data.msg
                    this.$parent.notification.className = err.response.data.className
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            },
            handleCancel() {
                this.$parent.showGuideEdit = false
            }
        }
    }


</script>