<template>
  <div class="modal user-usage-stats">
        <div class="modal-background"></div>
        <div class="modal-card user-info-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ user.email }}</p>
            <button @click="handleCancel()" class="delete" aria-label="close"></button>
          </header>
          <section class="modal-card-body">
            <div class="columns is-dark user-usage-body is-flex-direction-row has-text-weight-bold">
              <div class="column is-8">Video</div>
              <div class="column is-1 has-text-centered">25%</div>
              <div class="column is-1 has-text-centered">50%</div>
              <div class="column is-1 has-text-centered">75%</div>
            </div>
              <div v-for="video in videos" class="column video-stat">
                <div class="columns">
                  <div class="column is-8">{{ video.title }}</div>
                  <div v-if="video.quarter.includes(user.email)" class="column is-1"><button class="button is-success is-small"><font-awesome-icon style="color: white;" icon="fa-solid fa-check" /></button></div>
                  <div v-if="video.half.includes(user.email)" class="column is-1"><button class="button is-success is-small"><font-awesome-icon style="color: white;" icon="fa-solid fa-check" /></button></div>
                  <div v-if="video.threequart.includes(user.email)" class="column is-1"><button class="button is-success is-small"><font-awesome-icon style="color: white;" icon="fa-solid fa-check" /></button></div>
                </div>
              </div>
          </section>
          <footer class="modal-card-foot user-info-foot">
              <div class="lastMileStone">
                  <p>Siste milepæl: {{ user.lastMileStone ? user.lastMileStone : 'Foreløpig ingen milepæl' }}</p>
              </div>
          </footer>
        </div>
      </div>
  </template>
  
  <script>
  import axios from 'axios'

  export default {
      props: ['user'],
      data() {
          return {
            videos: []
          }
      },
      methods: {
          handleCancel() {
              document.querySelector('.user-usage-stats').classList.toggle('is-active')
          },
          async fetchVideos() {
            try {
              const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/videos/admin/all', {
                headers: {
                  'Authorization': 'Bearer ' + this.$parent.getToken
                }
              })

              this.setVideos(response.data)
            } catch(err) {
              console.log(err)
            }
          },
          setVideos(arr) {
            this.videos = [...arr]
          }
      },
      components: {
      },
      mounted() {
        this.fetchVideos()
      }
  }
  </script>
  
  <style scoped>
      label {
          font-weight: bold;
      }
  
      .modal-card-title {
          font-weight: bold;
      }

      .video-stat:nth-child(even) {
        background-color: #f0f0f0;
      }
  </style>