<template>
<div class="modal" :class="{ 'is-active': this.$parent.showWarningBox}">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="card">
          <div class="card-content">
            <div class="content has-text-centered">
              <p class="is-size-5">{{ message }}</p>
              <button @click="this.$parent.confirmDeletion = true" class="button mx-4">Ja</button>
              <button @click="this.$parent.showWarningBox = false" class="button is-danger">Avbryt</button>
            </div>
            </div>
          </div>
        </div>
  </div>
</template>
<script>
    export default {
        props: ['message']
    }
</script>

<style>

</style>