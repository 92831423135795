<template>
    <router-view/>
</template>
<script>
import { mapGetters } from 'vuex';


export default {
  components: {
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      'getRole'
    ])
  }
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
