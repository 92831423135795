<template>
<div>
    <EndUserNav />
    <PDFViewer
        :source="url"
        style="height: 100vh; width: 100vw"
        :controls="[
        'zoom',
        'catalog',
        'switchPage',
        ]"
        :loading-text="'Laster tekst'"
        :rendering-text="'Rendrer tekst'"
    />
</div>
</template>

<script>
import EndUserNav from '@/components/EndUserNav.vue';
import PDFViewer from 'pdf-viewer-vue'
import axios from 'axios'
import { mapGetters } from 'vuex';

export default {
    components: {
        EndUserNav,
        PDFViewer
    },
    data() {
        return {
            url: ''
        }
    },
    methods: {
        async setPdfSource() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_ENVIRONMENT === 'production' ? process.env.VUE_APP_URL_PROD : process.env.VUE_APP_URL_DEV}` + '/api/v1/guide/' + this.$route.params.id, {
                    headers: {
                    'Authorization': `Bearer ${this.getToken}`
                    }
                })
                if(response.status == 200) {
                    this.url = response.data.url
                }
            
            } catch(err) {
                console.log(err)
            }
        }
    },
    mounted() {
        this.setPdfSource()
    },
    computed: {
        ...mapGetters([
            'getToken'
        ])
    }
}

</script>