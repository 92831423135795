import { createRouter, createWebHistory } from 'vue-router'
import UserDashboard from '../views/UserDashboard.vue'
import LoginView from '../views/LoginView.vue'
import MediaLibrary from '../views/MediaLibraryView.vue'
import ContentView from '../views/ContentView.vue'
import RegisterUser from '../views/RegisterUser.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import PublishedMedia from '../views/PublishedMedia.vue'
import ArchivedContent from '../views/ArchivedContent.vue'
import GuideView from '../views/GuideView.vue'
import ApprenticeView from '../views/ApprenticeCourse.vue'
import PDFViewer from '../views/PDFViewer.vue'
import PathNotFound from '../views/PathNotFound.vue'
import { routeGuard } from './routeGuards'
import { adminRouteGuard } from './routeGuards'
import { apprenticeRouteGuard } from './routeGuards'


const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginView
        },
        {
        path: '/register',
        name: 'register',
        component: RegisterUser
        },
        {
          path: '/forgot',
          name: 'forgot',
          component: ForgotPassword
        },
        {
          path: '/reset/:string',
          name: 'reset',
          component: ResetPassword
        },
        {
        path: '/content',
        name: 'kursportal',
        component: ContentView,
        beforeEnter: routeGuard
        },
        {
        path: '/archive',
        name: 'archived-content',
        component: ArchivedContent,
        beforeEnter: routeGuard
        },{
        path: '/guides',
        name: 'guides',
        component: GuideView,
        beforeEnter: routeGuard
        },
        {
        path: '/media-library',
        name: 'media library',
        component: MediaLibrary,
        beforeEnter: adminRouteGuard
        }, {
        path: '/dashboard',
        name: 'dashboard',
        component: UserDashboard,
        beforeEnter: adminRouteGuard
        },
        {
          path: '/published',
          name: 'videos',
          component: PublishedMedia,
          beforeEnter: adminRouteGuard
        },
        {
          path: '/apprentice',
          name: 'apprentice',
          component: ApprenticeView,
          beforeEnter: apprenticeRouteGuard
        },
        {
          path: '/pdf-viewer/:id',
          name: 'pdf-viewer',
          component: PDFViewer,
          beforeEnter: routeGuard
        },
        { 
        path: '/:pathMatch(.*)*',
        component: PathNotFound
       }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
